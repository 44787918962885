import s from "./About.module.css"
import left from '../../assets/about/left/1.jpg'
import rightCert0 from '../../assets/about/right/sert01.jpeg'
import rightCert1 from '../../assets/about/right/sert04.jpeg'
import rightCert2 from '../../assets/about/right/sert3.jpg'
import rightCert3 from '../../assets/about/right/sert0.jpg'
import rightFeedback1 from '../../assets/about/right/feedback1.jpg'
import rightFeedback2 from '../../assets/about/right/feedback2.jpeg'
import rightFeedback3 from '../../assets/about/right/feedback3.png'
import rightFeedback4 from '../../assets/about/right/feedback4.png'
import ModalFunc from "../../common/Modal/Modal";

export const About = () => {

    const imagesRight2 = [
        {
            "text": "Certificate 2",
            "link": rightCert0,
        },
        {
            "text": "Certificate 2",
            "link": rightCert1,
        },
        {
            "text": "Certificate 3",
            "link": rightCert2,
        },
        {
            "text": "Certificate 1",
            "link": rightCert3,
        },
    ]

    const imagesRight1 = [
        {
            "text": "Feedback 2",
            "link": rightFeedback2,
        },
        {
            "text": "Feedback 3",
            "link": rightFeedback3,
        },
        {
            "text": "Feedback 4",
            "link": rightFeedback4,
        },
        {
            "text": "Feedback 1",
            "link": rightFeedback1,
        },
    ]

    return (
        <div className={s.about} id="about">

            <div className={s.secondTitle}>
                <h2>Давайте знакомиться!</h2>
            </div>

            <div className={s.aboutContent}>

                <div className={s.aboutLeft}>
                    <div className={s.container}>
                        <p>Мы работаем с 2014 года и за последние 9 лет поставили более 700
                            единиц оборудования и запчастей по всей России</p>
                        <img src={left}/>
                    </div>
                </div>

                <div className={s.aboutRight}>
                    <div className={s.container}>
                        <ul>
                            <li data-emoji="🔰">Что говорят о нас клиенты</li>
                        </ul>
                        <ModalFunc images={imagesRight1} isGrid={false}/>
                        <p style={{fontSize: "16px", fontStyle: "italic", margin: 0, display: 'inline-block'}}>
                            нажмите, чтобы увеличить
                        </p>🔎
                        <ul>
                            <li data-emoji="🔰">Сертификаты и лицензии</li>
                        </ul>
                        <ModalFunc images={imagesRight2} isGrid={false}/>
                    </div>
                </div>

            </div>

        </div>
    )
}