import s from "./Scheme.module.css"

export const Scheme = () => {

    return (
        <div className={s.scheme} id="scheme">

            <div className={s.secondTitle}>
                <h2><span>Современные</span> компоненты оборудования</h2>
            </div>

            <div className={s.schemeContent}>

                <div className={s.schemeLeft}>
                    <div>
                        <p>Механизм подачи и удержания</p>
                        <ul>
                            <li data-emoji="•">Автоматическая подача пленки</li>
                            <li data-emoji="•">Верхний прижим придаёт устойчивость</li>
                            <li data-emoji="•">Термическое отрезание пленки</li>
                            <li data-emoji="•">Удобный захват вилочным погрузчиком</li>
                        </ul>
                    </div>
                    <div>
                        <p>Механизм обертывания</p>
                        <ul>
                            <li data-emoji="•">Экономия 30% пленки благодаря технологии "престрейч"</li>
                            <li data-emoji="•">Растяжение пленки 0-250%</li>
                            <li data-emoji="•">Увеличенный поворотный стол</li>
                            <li data-emoji="•">Регулируемая скорость упаковки</li>
                        </ul>
                    </div>
                </div>

                <div className={s.middle}/>

                <div className={s.schemeRight}>
                    <div>
                        <p>Датчики и электроника</p>
                        <ul>
                            <li data-emoji="•">Датчик определения высоты груза, регулировка</li>
                            <li data-emoji="•">Плавный старт и остановка</li>
                            <li data-emoji="•">Датчик фазы остановки поворотного стола</li>
                            <li data-emoji="•">Защищенный электрический шкаф от скопления пыли и влаги</li>
                            <li data-emoji="•">Различные режимы обмотки для конкретного груза</li>
                        </ul>
                    </div>
                    <div>
                        <p>Другие особенности нашего оборудования</p>
                        <ul>
                            <li data-emoji="•">Устройство взвешивания</li>
                            <li data-emoji="•">Возвращение поворотной платформы в исходную позицию</li>
                            <li data-emoji="•">Автоматизация Роботом</li>
                            <li data-emoji="•">Прочный корпус и цепи, износостойкая облицовка</li>
                            <li data-emoji="•">Устройство защиты от травм, экстренная остановка</li>
                        </ul>
                    </div>
                </div>

            </div>

        </div>
    )
}