import s from "./Supplies.module.css"
import photo1 from '../../assets/payback/left/6.png'
import photo2 from '../../assets/payback/left/8.png'
import photo3 from '../../assets/payback/left/9.png'
import photo4 from '../../assets/payback/left/1.png'
import Item from "./Item/Item";

export const Supplies = () => {

    const suppliesData = [
        {
            city: "Чита", equipment: 'Упаковщик NT1600F-Y',
            description: [
                'Размеры упаковки - 600 мм *600 мм * (200-800) мм, либо на заказ',
                'Производительность упаковывания - 20-40 в час',
                'Скорость упаковывания - 0-12 об/мин',
                'Размеры поворотного стола - 609 мм',
                'Макс. нагрузка поворотного стола - 50 кг',
                'Стойка для пленки - Установка предварительного вытягивания',
                'Система управления - ПЛК',
                'Тип устройства прижимания сверху - Пневматическое прижимание',
                'Размеры оборудования - 600 мм * 1740 мм * 1800 мм',
            ],
            img: photo1,
            features: 'Предоставлена расширенная гарантия'
        },
        {
            city: "Красноярск", equipment: 'Упаковщик GPC-50C',
            description: [
                'Скорость транспортировки - 6 кор./мин.',
                'Размеры упаковки - Макс.: Д 600 * Ш 500 * В 500 мм',
                'Размеры применяемой клейкой ленты - Ш 48 мм / 60 мм / 75 мм (по выбору)',
                'Пневматическая установка - 6 кг/см3',
                'Источник питания - 220 В / 380 В, 50-60 Гц, 1200 Вт',
                'Размеры оборудования - Д 2460 * Ш 1420 * В 1650 мм',
            ],
            img: photo2,
            features: 'Доставили в сжатые сроки, несмотря на закрытие таможни'
        },
        {
            city: "Армавир", equipment: 'Упаковщик T1650FZ-PL',
            description: [
                'Размеры упаковки - Д(500-1200) мм * Ш (500-1200) мм',
                'Производительность упаковывания - 20-40 в час',
                'Скорость упаковывания - 0-12 об/мин',
                'Размеры поворотного стола - 1650 мм',
                'Макс. нагрузка поворотного стола - 2000 кг',
                'Стойка для пленки - Установка предварительного вытягивания',
                'Система управления - ПЛК',
                'Рабочее пневматическое давление - 0,6-0,8 Мпа, 1000 мл/мин.',
                'Размеры оборудования - 2745 мм * 1650 мм * (2260-3260) мм',
            ],
            img: photo3,
            features: 'Добавлено несколько расходных материалов'
        },
        {
            city: "Хабаровск", equipment: 'Упаковщик T1800FZ-PL',
            description: [
                'Размеры упаковки - Д(800-1200) мм * Ш (800-1100) мм',
                'Высота упаковки - 1800-2800 мм',
                'Скорость упаковывания - 0-12 об/мин',
                'Размеры поворотного стола - диаметр 2000 мм, высота: 490 мм',
                'Макс. нагрузка поворотного стола - 3000 кг',
                'Стойка для пленки - Установка предварительного вытягивания',
                'Система управления - ПЛК',
                'Размеры оборудования - 3000 мм * 2000 мм * (2650-3650) мм',
            ],
            img: photo4,
            features: 'Проведено обучение работников заказчика'
        },
    ]

    return (
        <div className={s.supplies} id="supplies">

            <div className={s.secondTitle}>
                <h2>Примеры поставок оборудования</h2>
            </div>

            <div className={s.suppliesContent}>
                        {suppliesData.map((s, i) => <Item key={i}
                                                                       city={s.city}
                                                                       equipment={s.equipment}
                                                                       description={s.description}
                                                                       img={s.img}
                                                                       features={s.features}/>
                        )}
            </div>

        </div>
    )
}