import s from "./Commercial.module.css"
import React from "react";
import {Form} from "../../common/Form/Form";

export const Commercial = () => {

    return (
        <div className={s.commercial} id="commercial">
            <div className={s.commercialContent}>

                <div className={s.commercialLeft}>

                    <div className={s.container}>
                        <div className={s.secondTitle}>
                            <h3>Закажите коммерческое предложение прямо сейчас</h3>
                            <p>И получите скидку при подписании договора в течение<br/>2 недель до 70 000 руб, а также:</p>
                        </div>
                        <ul>
                            <li data-emoji="•">2-3 варианта упаковочного оборудования</li>
                            <li data-emoji="•">поставка из наличия и точные сроки</li>
                            <li data-emoji="•">гарантия до 3 лет</li>
                            <li data-emoji="•">сертификация ЕАС и СЕ</li>
                            <li data-emoji="🎁">Срок службы 10 лет</li>
                        </ul>
                        <a href="#callbackwidget"><button>Получить коммерческое предложение</button></a>
                    </div>

                </div>

                <div className={s.commercialRight}>
                    <iframe width="90%" height="315"
                            src="https://www.youtube.com/embed/ik96y2DCfeE?si=b9FA1hEbBU9wcVb6"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe>
                    {/*<Form/>*/}
                </div>

            </div>
        </div>
    )
}