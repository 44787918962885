import s from "./Payback.module.css"
import leftPhoto1 from '../../assets/payback/left/1.png'
import leftPhoto2 from '../../assets/payback/left/2.png'
import leftPhoto3 from '../../assets/payback/left/3.png'
import leftPhoto4 from '../../assets/payback/left/4.png'
import leftPhoto5 from '../../assets/payback/left/5.png'
import leftPhoto6 from '../../assets/payback/left/6.png'
import leftPhoto7 from '../../assets/payback/left/7.png'
import leftPhoto8 from '../../assets/payback/left/8.png'
import leftPhoto9 from '../../assets/payback/left/9.png'
import leftPhoto10 from '../../assets/payback/left/10.png'
import rightPhoto1 from '../../assets/payback/photo1.jpg'
import rightPhoto2 from '../../assets/payback/photo2.jpg'
import rightPhoto3 from '../../assets/payback/photo3.jpg'
import rightPhoto4 from '../../assets/payback/photo4.jpg'
import rightPhoto5 from '../../assets/payback/photo5.jpg'
import ModalFunc from "../../common/Modal/Modal";
import Carousel from "../../common/Carousel/Carousel";

export const Payback = () => {

    const imagesLeft = [
        {
            "text": "Description of image",
            "link": leftPhoto1,
        },
        {
            "text": "Rubber paver machine TPJ",
            "link": leftPhoto2,
        },
        {
            "text": "Rubber mixer",
            "link": leftPhoto3,
        },
        {
            "text": "Description of image",
            "link": leftPhoto4,
        },
        {
            "text": "Description of image",
            "link": leftPhoto5,
        },
        {
            "text": "Description of image",
            "link": leftPhoto6,
        },
        {
            "text": "Description of image",
            "link": leftPhoto7,
        },
        {
            "text": "Description of image",
            "link": leftPhoto8,
        },
        {
            "text": "Description of image",
            "link": leftPhoto9,
        },
        {
            "text": "Description of image",
            "link": leftPhoto10,
        },
    ];
    const imagesRight = [
        {
            "text": "Description of image",
            "link": rightPhoto1,
        },
        {
            "text": "Description of image",
            "link": rightPhoto2,
        },

        {
            "text": "Description of image",
            "link": rightPhoto5,
        },
        {
            "text": "Description of image",
            "link": rightPhoto3,
        },
        {
            "text": "Description of image",
            "link": rightPhoto4,
        },
    ];

    return (
        <div className={s.payback} id="payback">

            <div className={s.secondTitle}>
                <h2>Поможем наладить <span>упаковку грузов</span> на вашем складе</h2>
            </div>

            <div className={s.paybackContent}>

                <div className={s.paybackLeft}>
                    <div className={s.container}>
                        <p style={{width: '100%', textAlign: 'center'}}>Примеры мобильного, автоматического и полуавтоматического оборудования:</p>
                        <Carousel images={imagesLeft}/>
                        <ul>
                            <li data-emoji="🔰">Паллетоупаковщики</li>
                            <li data-emoji="🔰">Конвейеры</li>
                            <li data-emoji="🔰">Мобильные Робот-упаковщики</li>
                            <li data-emoji="🔰">Машины для работы с коробками</li>
                            <li data-emoji="🔰">Упаковщики в рулоны</li>
                            <li data-emoji="🔰">...а также запчасти</li>
                        </ul>
                        <p style={{marginTop: 0}}>На все оборудование действует гарантия <b>12 месяцев</b>,
                            возможна расширенная гарантия и пост-гарантийное обслуживание.</p>
                    </div>
                </div>

                <div className={s.paybackRight}>
                    <div className={s.container}>
                        <p>Доставим и настроим "под ключ" даже самое сложное оборудование, подписание акта
                            ввода только после пробного включения</p>
                        <ModalFunc images={imagesRight} isGrid={true}/>
                        <p style={{fontSize: "16px", fontStyle: "italic", margin: 0, marginTop: "5px", display: 'inline-block'}}>
                            нажмите, чтобы увеличить
                        </p>🔎
                        <ul>
                            <li data-emoji="🔰">Собственные выездные инженеры</li>
                            <li data-emoji="🔰">Собственная система обучения вашего персонала</li>
                        </ul>
                    </div>
                </div>

            </div>

        </div>
    )
}